const Utils = window.relevantDigital.import('Utils');

// We might need to temporary display hidden elements in order to get the correct position
const getClientRect = (elm) => {
	const arr = [];
	let prevX;
	let prevY;
	for (let node = elm; node && getComputedStyle(node, null).display === 'none'; node = node.parentNode) {
		if (node.style) {
			if (prevY === undefined) {
				prevY = window.scrollY;
				prevX = window.scrollX;
			}
			arr.push({ node, display: node.style.display });
			node.style.display = '';
		}
	}
	const res = elm.getBoundingClientRect();
	arr.forEach(({ node, display }) => {
		node.style.display = display;
	});
	if (prevY !== undefined && prevY !== window.scrollY) {
		window.scrollTo(prevX, prevY);
	}
	return res;
};

class ReloadState {
	// settings: reloader, auction, adUnit, slot, enabled, timeBetween, strategy, reloadTimes, minVisibility, keepDims
	constructor(settings) {
		const { reloadTimes } = settings;
		Utils.assign(this, {
			...settings,
			leftToSchedule: reloadTimes, // number of reloads left
			lastCountTs: null, // Is set to last timestamp of update() if we counted the time at that moment
			countedTime: 0, // How far have we "counted" down until next reload.
			viewableNow: false, // Was placement viewable during last update() call
			filter: (settings.filter || []).filter((f) => f.value || f.liType.length), // Skip "empty" filters
		});
	}

	element() {
		return document.getElementById(this.divId());
	}

	divId() {
		return this.slot.getSlotElementId();
	}

	// State is not valid if the element is gone / have changed (on SPA page "switch")
	isStillValid() {
		return !(this.lastElementRef && this.lastElementRef !== this.element());
	}

	setHidden() {
		this.lastCountTs = null;
	}

	// To be called when we're about to be reloaded
	scheduleNextReload() {
		this.lastCountTs = null;
		this.countedTime = 0;
		this.leftToSchedule -= 1;
		if (this.keepDims) { // Avoid "jumps" when going from an ad => no ad
			const div = this.element();
			if (div) {
				const style = getComputedStyle(div);
				div.style.minWidth = style.width;
				div.style.minHeight = style.height;
			}
		}
		return this.leftToSchedule > 0; // do we still have more reloads to do?
	}

	// Is the ad-div viewable according to 'Minium viewablity % for reload'
	isViewable() {
		const div = this.element();
		if (!div) {
			return false;
		}
		const rect = getClientRect(div);
		const { left, top } = rect;
		let { width, height } = rect;
		// For empty ad-slots, count viewability using the primary dimensions
		if (!width || !height) {
			if (!left && !top) {
				return false; // getClientRect probably failed to get the right rectangle, let's skip reload
			}
			[width, height] = this.adUnit.getPrimaryPrebidSize(); // use default size of placement
		}
		const { innerWidth, innerHeight } = window;
		const visibleWidth = Math.min(innerWidth, left + width) - Math.max(left, 0);
		const visibleHeight = Math.min(innerHeight, top + height) - Math.max(top, 0);
		const minPerc = this.minVisibility / 100;
		return visibleWidth > 0 && visibleHeight > 0 && (visibleWidth * visibleHeight) > (width * height * minPerc);
	}

	update(updateTs) {
		// Ignore this ReloadState if we haven't rendered the ad (e.g. by calling googletag.refresh())
		if (!this.reloader.pbRequester.hasRenderedDivId(this.divId())) {
			this.lastCountTs = null;
			return;
		}
		// For checking if element dissapears/changes later (making this ReloadState not valid)
		this.lastElementRef = this.element();

		const viewable = this.isViewable();

		// Should we counting down time until next reload at this time? This depends on the "strategy"
		let countThis;
		if (this.strategy === 'load') { // always count the time after rendering
			countThis = true;
		} else if (this.strategy === 'viewStart') { // count time after being viewable once
			countThis = viewable || this.lastCountTs;
		} else { // this.strategy === 'sticky' => only count while being viewable
			countThis = viewable;
		}
		// If we counted down time until next reload during last update AND now, then add the time-span to 'countedTime'
		if (countThis && this.lastCountTs) {
			this.countedTime += updateTs - this.lastCountTs;
		}
		Utils.assign(this, {
			viewableNow: viewable,
			lastCountTs: countThis ? updateTs : null,
		});
	}

	// Are we ready for next reload?
	isReloadReady() {
		return this.countedTime >= this.timeBetween * 1000 && this.viewableNow;
	}

	isReloadAllowed() {
		const {
			fType, filter, adUnit, lastInstance, reloader,
		} = this;
		const { hbWon } = lastInstance;

		const response = adUnit.adserver.getSlotResponseInfo(this);
		if (!response || !this.filter.length) {
			return true;
		}

		const { seenLineItems } = reloader;
		const { adsType } = seenLineItems[adUnit.adserver.id]?.[response.lineItemId] || {};

		const checkMatch = ({ type, liType, value }) => {
			const idKey = type === 'order' ? 'orderId' : 'advertiserId';
			// eslint-disable-next-line eqeqeq
			const idMatches = !value || value == response[idKey];
			const liTypeMatches = !liType.length
				|| liType.includes(String(adsType))
				|| (liType.includes('hb') && hbWon)
				|| (!hbWon && !adsType && fType === 'excl');
			return idMatches && liTypeMatches;
		};
		const match = Utils.find(filter, checkMatch);
		return fType === 'incl' ? match : !match;
	}
}

module.exports = ReloadState;
