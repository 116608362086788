const { NATIVE_FIELDS, NATIVE_IMAGE_FIELDS, TITLE_DEF_LEN } = require('relevant-shared/prebid/native');
const { DEF_FLOOR } = require('./sharedConstants');
const Utils = require('./utils');
const BidUtils = require('./bidUtils');
const BidderHandler = require('./bidderHandler');

const { AmazonInterface } = window.relevantDigital.exports;

/**
 * This class represents a placement in the prebid configuration. Notice that the same placement can be used
 * any number of times on the page. Therefore one AdUnit might generate multiple entries in pbjs.adUnits.
 * For this purpose we create one AdUnitInstance object for every element in pbjs.adUnits. Example:
 *
 * <div data-ad-unit-id="/12345/top-banner"><div>
 * <div data-ad-unit-id="/12345/top-banner"><div>
 *
 * In this case there will be one AdUnit object in the auction but two AdUnitInstance objects,
 * both referring to the same AdUnit.
 */
class AdUnit {
	constructor({ adUnitJson, auction, adserver }) {
		const { dimensionValidFn, viewport } = auction;
		Utils.assign(this, {
			s2sNativeImageAddFlds: [], // mediaType.native fields that needs adjustments for PBS
			fixedSlots: [],
			auction,
			adserver,
			adUnitJson,
			...Utils.clone(adUnitJson),
		});
		this.amazonBid = AmazonInterface?.getFirstValidAmazonBid(this);
		const placementType = this.getPlacementType();
		const validDimensions = [];
		const invalidDimensions = [];
		const dimValidFn = dimensionValidFn || BidUtils.isDimensionValid;
		placementType.dimensions.forEach((dimension) => {
			const dimParams = {
				dimension,
				adUnit: this,
				placementType,
				auction,
				viewport,
			};
			(dimValidFn(dimParams) ? validDimensions : invalidDimensions).push(dimension);
		});
		const sizes = validDimensions.map(({ width, height }) => [width, height]);
		const {
			videoSettings, nativeSettings, isInstream, formats,
		} = this;
		const mediaTypes = {};
		if (formats.banner) {
			mediaTypes.banner = { sizes };
		}
		if (formats.video) {
			const {
				playerExclusiveOptions,
				adserverTargetingOptions,
				mediaType,
				...otherOptions
			} = videoSettings;
			mediaTypes.video = {
				...otherOptions,
				context: isInstream ? 'instream' : 'outstream',
			};
		}
		if (nativeSettings) {
			mediaTypes.native = nativeSettings;
			NATIVE_FIELDS.forEach((fld) => {
				let setting = nativeSettings[fld];
				if (setting?.disabled) {
					delete nativeSettings[fld];
					return;
				}
				if (!setting) {
					setting = { required: false };
					nativeSettings[fld] = setting;
				}
				if (NATIVE_IMAGE_FIELDS.indexOf(fld) >= 0) {
					if (!setting.sizes && !setting.aspect_ratios) {
						// We need to set aspect_ratios for PBS-adapter, on the other hand these
						// values seems to break Xandr-calls client-side, so they should be different client/s2s
						this.s2sNativeImageAddFlds.push(fld);
					}
				} else if (fld === 'title' && !setting.len) {
					setting.len = TITLE_DEF_LEN; // Required by PBS-adapter
				}
			});
		}
		this.pbAdUnit = {
			mediaTypes,
			sizes,
			bids: BidUtils.filterBids({ bids: this.bids, validDimensions, invalidDimensions }),
		};
		if (adserver.adUnitInit) {
			adserver.adUnitInit({ adUnit: this });
		}
	}

	initFloor() {
		if (!this.floorOptimized && this.data.rlvFloor && !this.data.rlvFloorOptOnly) {
			this.setBidFloor(this.data.rlvFloor, this.data.rlvFloorCur, { internalCall: true });
		}
	}

	// We need to split into separate client/s2s ad unit units either if we need to add aspect_ratio for images
	needFinalizeForS2s(pbAdUnit) {
		return pbAdUnit.mediaTypes.native && this.s2sNativeImageAddFlds.length;
	}

	// Add mediaTypes.native.[image-type].aspect_ratio
	finalizeForS2s(pbAdUnit) {
		const { s2sNativeImageAddFlds: flds } = this;
		const res = {
			...pbAdUnit,
			mediaTypes: {
				...pbAdUnit.mediaTypes,
				native: { ...pbAdUnit.mediaTypes.native },
			},
		};
		flds.forEach((fld) => {
			res.mediaTypes.native[fld] = {
				...res.mediaTypes.native[fld],
				aspect_ratios: [{
					min_width: 1,
					min_height: 1,
				}],
			};
		});
		return res;
	}

	setBidFloor(floor, currency, settings) {
		const external = !settings?.internalCall;
		if (external && this.floorOptimized) {
			return; // If optimization is enabled, ignore this (else optimization might go crazy)
		}
		this.floorSet = floor;
		const pbFloor = floor === 0 ? DEF_FLOOR : floor;
		let floors = typeof floor !== 'number' ? floor : {
			default: pbFloor,
			schema: { fields: ['mediaType'] },
			values: { '*': pbFloor },
			...(currency && { currency }),
		};
		this.pbAdUnit.floors = floors;
		if (!floors) {
			floors = { default: null };
		}
		if ('default' in floors) {
			this.pbAdUnit.bids.forEach((bid) => {
				BidderHandler.of(bid).setFloor({
					params: bid.params,
					bidFloor: floors.default,
					...(floors.currency ? { bidFloorCurrency: floors.currency } : null),
				});
			});
		}
		if (external) {
			this.auction.onExternalSetBidFloor();
		}
	}

	getBidFloor() {
		const { default: floor, currency } = this.pbAdUnit.floors || {};
		return typeof floor !== 'number' ? null : { floor, currency: currency || 'USD' };
	}

	getAdUnitPaths() {
		return this.adserver.adUnitPathsFromUnit(this);
	}

	getPrebidSizes(alwaysAsArrayOfArrays) {
		const { mediaTypes, sizes } = this.pbAdUnit;
		let res = (mediaTypes.banner || {}).sizes || (mediaTypes.video || {}).playerSize || sizes || [];
		if (alwaysAsArrayOfArrays && res.length === 2 && !Array.isArray(res[0])) {
			res = [res];
		}
		return res;
	}

	getPrimaryPrebidSize() {
		const sizes = this.getPrebidSizes();
		return Array.isArray(sizes[0]) ? sizes[0] : sizes;
	}

	getPlacementType() {
		return this.auction.placementTypesById[this.placementTypeId];
	}

	assignSlot(slot) {
		this.fixedSlots.push(slot);
	}

	createSlot(params = {}) {
		const fallbackVal = `rlv-rnd-${Math.random()}`;
		const slot = this.adserver.createSlotFromAdUnit({
			adUnit: this,
			path: fallbackVal,
			divId: fallbackVal,
			...params,
		});
		this.assignSlot(slot);
		return slot;
	}
}

module.exports = AdUnit;
