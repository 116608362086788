const ReloadState = require('./reloadState');
const Utils = window.relevantDigital.import('Utils');

const DEFAULT_UPDATE_MS = 1000; // How often we should check viewability and possibly reload placements
const DEFAULT_MAX_NO_ACTIVITY_MS = 300 * 1000; // stop reloading after 5 minutes of no scrolling

/**
 * "Singleton-like" object to handle all ReloadState objects. One ReloadState is created for every placement
 * that should be reloaded and is deleted after the final reload for the placement has been triggered. Reloads
 * are triggered by calling PrebidRequester.loadPrebid() again but only for the placements that should be reloaded.
 */
class Reloader {
	constructor({ pbRequester }) {
		Utils.assign(this, {
			pbRequester,
			stateById: {}, // ReloadState by ad-div id
			active: false,
			updateMs: DEFAULT_UPDATE_MS,
			maxNoActivityMs: DEFAULT_MAX_NO_ACTIVITY_MS,
			listenersInitialized: false,
			seenLineItems: {},
		});
		this.pbRequester = pbRequester;
		pbRequester.addAuctionDoneListener((a) => this.onAuctionDone(a));
	}

	onAuctionDone(auction) {
		const { isReloadAuction } = auction;
		auction.usedUnitDatas.forEach((adUnitInstance) => {
			const { adUnit, slot } = adUnitInstance;
			const settings = adUnit.data.rlvReloadSettings || {};
			if (!settings.enabled || !settings.timeBetween || !settings.reloadTimes) {
				return; // Reloading disabled for placement
			}
			const elmId = slot.getSlotElementId();
			let state = this.stateById[elmId];
			if (state) {
				// If the ad-div has been replaced (maybe by navigating on a SPA-page) or if a new auction is triggered
				// for the placement - reset the state and thereby the reload-counter
				if (!state.isStillValid() || !isReloadAuction) {
					state = null; // reset state
				}
			}
			if (!state && !isReloadAuction) {
				state = new ReloadState({
					...settings,
					reloader: this,
					auction,
					adUnit,
					slot,
					lastInstance: adUnitInstance,
				});
				this.stateById[elmId] = state;
			}
		});
		if (Object.keys(this.stateById).length) {
			if (!this.listenersInitialized) {
				this.lastActivityTs = new Date();
				this.listenersInitialized = true;
				window.addEventListener('scroll', () => {
					this.lastActivityTs = new Date();
				});
			}
			this.scheduleUpdate(); // Starts update-loop, if needed
		}
	}

	scheduleUpdate() {
		if (!this.updateScheduled) {
			this.updateScheduled = true;
			setTimeout(() => this.update());
		}
	}

	maybeUpdateLineItemInfo(toReload, callback) {
		const missingLineItems = {};
		const { seenLineItems } = this;
		toReload.forEach((arr) => {
			arr.forEach(({ filter, lastInstance: data }) => {
				/**
				 * Line-item information is only needed if at least one filterhas a Line Item Type other than "Headerbidding (hb)".
				 * If "hb" is the only Line Item Type present in all filters, there's no need to fetch line-item data.
				 */
				if (!filter.find(({ liType }) => liType.find((t) => t !== 'hb'))) {
					return;
				}
				const { id: adsId } = data.adserver;
				const { lineItemId: liId } = data.adserver.getSlotResponseInfo(data) || {};
				if (liId && !(liId in (seenLineItems[adsId] || {}))) {
					/**
					 * Mark line items without information as null to prevent repeated fetch attempts
					 * on subsequent calls.
					 */
					Utils.merge(missingLineItems, { [adsId]: { [liId]: null } });
				}
			});
		});
		Utils.runFns(Utils.entries(missingLineItems).map(([adserverId, byLi]) => (done) => {
			const { analyticsURL, systemId } = this.pbRequester;
			const lineItemIds = Object.keys(byLi).map(Number);
			Utils.request({
				url: `${analyticsURL}/analytics/line_item_info`,
				body: { adserverId, systemId, lineItemIds },
			}, done);
		})).then((arr) => {
			/**
			 * If missingLineItems was not included in the reponse, ensure
			 * that we do not attempt to fetch it again
			 */
			Utils.merge(seenLineItems, missingLineItems, ...arr);
			callback();
		});
	}

	update() {
		// Group ReloadState objects that should now be reloaded by auction to handle the possibility of auctions using
		// different settings. So placements for different calls to relevantDigital.laodPrebid() will not be reloaded
		// in the same reload auction.
		const toReload = new Map();

		const updateTs = new Date();

		// Count tab as "hidden" if there has been no scroll activity recently (user gone away..)
		const inactive = updateTs - this.lastActivityTs > this.maxNoActivityMs;
		const hiddenByType = {
			focus: () => !document.hasFocus(), // If mouse is inside page
			hidden: () => document.hidden, // Visibility API, might not work on Chrome/Linux
			none: () => false,
		};

		// eslint-disable-next-line guard-for-in
		for (const elmId in this.stateById) {
			const state = this.stateById[elmId];
			const hidden = inactive || hiddenByType[state.hiddenType || 'focus']();
			if (hidden) {
				state.setHidden(); // Stop count viewability-time
			} else if (!state.isStillValid()) {
				delete this.stateById[elmId]; // Ad-div has changed / been replaced => stop reloading
			} else {
				state.update(updateTs);
				if (state.isReloadReady()) { // Placement should be reloaded..
					const arr = toReload.get(state.auction) || [];
					arr.push(state);
					toReload.set(state.auction, arr);
					if (!state.scheduleNextReload()) { // This is the last reload, delete object..
						delete this.stateById[elmId];
					}
				}
			}
		}

		const reload = () => toReload.forEach((arr, auction) => {
			const allowedStates = arr.filter((state) => state.isReloadAllowed());
			if (!allowedStates.length) {
				return;
			}
			const settings = {
				...auction.settings, // copy previous auction-settings
				isReloadAuction: true,
				manageAdserver: false,
				noSlotReload: false,
				allowedDivIds: allowedStates.map(({ slot }) => slot.getSlotElementId()),
				mainAuction: auction.settings.mainAuction || auction,
				customParams: Utils.merge({ Reloading: 'Yes' }, auction.customParams),
			};
			[...auction.adservers].reverse().forEach((ads) => {
				if (!ads.isInstreamOnly()) {
					ads.finalizeReloadSettings(settings);
				}
			});
			this.pbRequester.loadPrebid(settings); // Trigger next auction
		});

		if (toReload.size) {
			// Use setTimeout to prevent possibility of this.onAuctionDone() being called immediately
			setTimeout(() => this.maybeUpdateLineItemInfo(toReload, reload));
		}
		// Schedule next check, but only if there are still active ReloadState objects
		this.updateScheduled = !!Object.keys(this.stateById).length;
		if (this.updateScheduled) {
			setTimeout(() => this.update(), this.updateMs);
		}
	}
}

window.relevantDigital.export({ Reloader });
